






















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { photoService } from "@/services/photo.service";
// eslint-disable-next-line no-unused-vars
import { PhotoCollectionEntity } from "@/entities/PhotoCollection.entity";

@Component
export default class Collections extends Vue {
  // Data
  photoCollections = new Array<PhotoCollectionEntity>();
  dialog: boolean = false;
  collectionToDelete: number = -1;
  headers = [
    { text: "Name", align: "start", sortable: false, value: "name" },
    { text: "Size", sortable: false, value: "size" },
    { text: "Length", sortable: false, value: "length" },
    { text: "Actions", value: "actions", sortable: false }
  ];

  // mounted
  mounted() {
    this.getPhotoCollections();
  }

  get isSuperuser() {
    return this.$store.getters.isSuperuser;
  }

  // Methods
  getPhotoCollectionName(photoCollection: any) {
    return photoCollection.upload.substr(
      photoCollection.upload.lastIndexOf("/") + 1
    );
  }

  getPhotoCollections() {
    photoService.getPhotoCollections().then(response => {
      this.photoCollections = response.data;
    });
  }

  deletePhotoCollection() {
    if (this.collectionToDelete < 0) {
      return;
    }
    photoService
      .deletePhotoCollection(this.collectionToDelete)
      .then(() => {
        this.getPhotoCollections();
      })
      .catch(response => {
        alert(
          "Error while deleting this photo collection. There may be a task that uses it. Check console for more details."
        );
        console.error(response);
      })
      .finally(() => {
        this.closeDialog();
      });
  }

  openDialog(collectionId: number) {
    this.collectionToDelete = collectionId;
    this.dialog = true;
  }

  closeDialog() {
    this.collectionToDelete = -1;
    this.dialog = false;
  }
}
